import request from "../utils/request";

//获取地图点
export function getTokenByCode(code) {
  return request.getF("/api/gettokenbycode?code=" + code, "get");
}

//获取地图点
export function getSSE(addr_path) {
  return request.getF("/api/sse?addr_path=" + addr_path, "get");
}

//弹窗相关
//商家详细信息
export function getBusDetail(busid) {
  return request.getF("/api/busines?busid=" + busid, "get");
}
//商家事件信息
export function getGWData(gwid, skip, limit) {
  return request.getF(
    `/api/getevents?gwid=${gwid}&skip=${skip}&limit=${limit}`,
    "get"
  );
}

//商家事件信息
export function getBusDataByGWid(gwid) {
  return request.getF(
      `/api/businesbygwid?gwid=${gwid}`,
      "get"
  );
}

