import axios from "axios";
import Qs from "qs";
// import router from "../router";

// 状态码对映的消息
const codeMessage = {
  200: "操作成功。",
  201: "新建或修改数据成功。",
  202: "一个请求已经进入后台排队（异步任务）。",
  204: "删除数据成功。",
  400: "发出的请求有错误，服务器没有进行新建或修改数据的操作。",
  401: "用户没有权限（令牌、用户名、密码错误）。",
  403: "用户得到授权，但是访问是被禁止的。",
  404: "发出的请求针对的是不存在的记录，服务器没有进行操作。",
  405: "请求方式不对",
  406: "请求的格式不可得。",
  410: "请求的资源被永久删除，且不会再得到的。",
  422: "当创建一个对象时，发生一个验证错误。",
  500: "服务器发生错误，请检查服务器。",
  502: "网关错误。",
  503: "服务不可用，服务器暂时过载或维护。",
  504: "网关超时。",
};
// 创建axios实例
export const baseURL = 'https://api.hwarise.com';//正式环境
// export const baseURL = 'http://192.168.31.39:7890';//正式环境

const service = axios.create({
  baseURL: baseURL,
  timeout: 30000,
});

// respone拦截器
service.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return response.data;
    } else {
      Promise.reject();
    }
  },
  (error) => {
    for (let code in codeMessage) {
      if (code === error.response.status) {
        // v.$notify.error({
        //   title: '失败'+code,
        //   message: codeMessage[code],
        //   duration: 2000
        // });
      }
    }
    if (error.response.status === "401") {
      localStorage.clear();
      sessionStorage.clear();
      //  router.push("/login");
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default {
  //post请求
  postQs(url, param) {
    let token = "";
    if (sessionStorage.getItem("Info")) {
      token = JSON.parse(sessionStorage.getItem("Info")).token;
    }

    return new Promise((resolve, reject) => {
      service({
        method: "post",
        url,
        data: Qs.stringify(param),
        headers: {
          Authorization: token,
        },
      })
        .then((res) => {
          resolve(res);
          if (!res.ok) {
            // v.$notify.error({
            //   title: '错误',
            //   message: res.errmsg,
            //   duration: 2000
            // });
          } else {
            // v.$notify.success({
            //   title: '成功',
            //   message: "操作成功",
            //   duration: 2000
            // });
          }
        })
        .catch((err) => {
          reject(err);
          // v.$notify.error({
          //   title: '错误',
          //   message: res.errmsg,
          //   duration: 2000
          // });
          // console.log(err, '异常')
        });
    });
  },
  //post请求
  postF(url, param, method, type) {
    let token = "";
    if (sessionStorage.getItem("beforeData")) {
      token = JSON.parse(sessionStorage.getItem("beforeData")).token;
    }
    return new Promise((resolve, reject) => {
      service({
        method,
        url,
        data: param,
        headers: {
          Authorization: token,
        },
      })
        .then((res) => {
          resolve(res);
          if (!res.ok) {
            if (!type) {
              // v.$notify.error({
              //   title: '失败',
              //   message: '操作失败',
              //   duration: 2000
              // });
            }
          } else {
            if (!type) {
              // v.$notify.success({
              //   title: '成功',
              //   message: '操作成功',
              //   duration: 2000
              // });
            }
          }
        })
        .catch((err) => {
          reject(err);
          // v.$notify.error({
          //     title: '错误',
          //     message: '未登录 或者token过期',
          //     duration:2000
          //   });
          // console.log(err, '异常')
        });
    });
  },
  //登陆页面获取登陆时候的Code的get请求
  getBefore(url, method) {
    let token = "";
    if (sessionStorage.getItem("beforeData")) {
      token = JSON.parse(sessionStorage.getItem("beforeData")).token;
    }
    return new Promise((resolve, reject) => {
      service({
        method,
        url,
        // params: param,
        headers: {
          Authorization: token,
        },
      })
        .then((res) => {
          resolve(res);
          if (!res.ok) {
            // v.$notify.error({
            //   title: '错误',
            //   message: res.errmsg,
            //   duration: 2000
            // });
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //进入大屏后的Get请求
  getF(url, method) {
    let token = "";
    if (sessionStorage.getItem("Info")) {
      token = JSON.parse(sessionStorage.getItem("Info")).token;
    }
    return new Promise((resolve, reject) => {
      service({
        method,
        url,
        // params: param,
        headers: {
          Authorization: token,
        },
      })
        .then((res) => {
          resolve(res);
          if (!res.ok) {
            // v.$notify.error({
            //   title: '错误',
            //   message: res.errmsg,
            //   duration: 2000
            // });
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //进入大屏后的post
  postBig(url, param, method, type) {
    let token = "";
    if (sessionStorage.getItem("Info")) {
      token = JSON.parse(sessionStorage.getItem("Info")).token;
    }
    return new Promise((resolve, reject) => {
      service({
        method,
        url,
        data: param,
        headers: {
          Authorization: token,
        },
      })
        .then((res) => {
          resolve(res);
          if (!res.ok) {
            if (!type) {
              // v.$notify.error({
              //   title: '失败',
              //   message: '操作失败',
              //   duration: 2000
              // });
            }
          } else {
            if (!type) {
              // v.$notify.success({
              //   title: '成功',
              //   message: '操作成功',
              //   duration: 2000
              // });
            }
          }
        })
        .catch((err) => {
          reject(err);
          // v.$notify.error({
          //     title: '错误',
          //     message: '未登录 或者token过期',
          //     duration:2000
          //   });
          // console.log(err, '异常')
        });
    });
  },
  service,
};
