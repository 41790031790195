<template>
  <div>
    <dv-border-box-8 v-if="loading || !hasData" class="busWin">
      <el-button class="btn" type="primary" @click="onClose">X</el-button>
      <div v-if="loading && !hasData" style="height: 100%">
        <dv-loading style="margin: auto">数据加载中...</dv-loading>
      </div>
      <div v-else style="height: 100%">
        <div style="padding: 30% 0; font-size: 46px">暂无商家信息！</div>
      </div>
    </dv-border-box-8>
    <div v-else class="busWin">
      <dv-border-box-8 style="width: 30%">
        <h1 class="title">{{ busDetail.name }}</h1>
        <form style="width: 80%; margin: 30px auto">
          <table style="width: 100%; font-size: x-large; line-height: 50px;text-align: left">
            <tr>
              <td>负责人：{{ busDetail.principal }}</td>
            </tr>
            <tr>
              <td>负责人电话：{{ busDetail.principal_mobile }}</td>

            </tr>
            <tr>
              <td>设备编号：{{ busDetail.gateway_id }}</td>
            </tr>
            <tr>
              <td>物联卡号码：{{ busDetail.gateway_mobile_no }}</td>
            </tr>
            <tr>
              <td>
                区域：{{ busDetail.address_path }}
              </td>
            </tr>
            <tr>
              <td>
                地址：{{ busDetail.address }}
              </td>
            </tr>
          </table>
        </form>
      </dv-border-box-8>
      <dv-border-box-8 style="width: 68%; margin-left: 20px">
        <el-button class="btn" type="primary" @click="onClose">X</el-button>
        <div
            v-if="!hasWarmingData"
            style="position: absolute; width: 100%; height: 100%"
        >
          <dv-loading style="margin: auto">数据加载中...</dv-loading>
        </div>
        <div
            v-if="!hasWarmingData"
            style="
            position: absolute;
            width: 99%;
            height: 99%;
            background-color: black;
            margin: 5px;
          "
        >
          <div style="margin: auto; font-size: xx-large; line-height: 900px">
            液罐离线，暂无数据...
          </div>
        </div>
        <div v-else style="display: flex;flex-direction: column">
          <h1 class="title">油箱实时数据<span v-if="isLow(busDetail.last_online_at)"
                                              style="color:red">(油箱已离线)</span></h1>
          <div style="width: 98%;height: 900px">
            <div class='box' :class="Dex>busDetail.low_fuel_warning?'':'danger'">
              <div class='wave -one' :style="`top:${10-Dex}%`"></div>
              <div class='wave -two' :style="`top:${10-Dex}%`"></div>
              <div class='wave -three' :style="`top:${10-Dex}%`"></div>
            </div>
            <div class="detailText">
              液位：{{ warmingData.ex }}%，温度：{{ warmingData.temp }}&#176;，流量：{{
                warmingData.lm
              }}
            </div>
          </div>
        </div>
      </dv-border-box-8>
    </div>
  </div>
</template>

<script>
import {getBusDetail, getGWData} from "@/api/common";
import dayjs from "dayjs";

export default {
  name: "busDetail",
  data() {
    return {
      loading: true,
      hasData: false,
      hasWarmingData: false,
      showOffLine: false,
      busDetail: {
        name: "",
        principal: "",
        principal_mobile: "",
        gateway_id: 0,
        gateway_mobile_no: "",
        address: "",
        address_path: "",
        low_fuel_warning: 0,
        last_online_at: 0,
      },
      warmingData: {},
      Dex: 0,
      gwid: "",
    };
  },
  computed: {
    isLow() {
      return (last_online_at) => {
        if (!last_online_at) return true;
        return Date.now() / 1000 - last_online_at >= 900;
      }
    }
  },
  methods: {
    init(busid, gwid) {
      this.showOffLine = false;
      this.gwid = gwid;
      const busPro = getBusDetail(busid).then((res) => {
        if (res && res.result) {
          this.busDetail = res.result;
        }
      });
      const GWPro = getGWData(gwid, 0, 1).then((res) => {
        if (res.code === 0 && res.result && res.result.length !== 0) {
          this.warmingData = {
            ex: res.result[0].ex / 100,
            temp: res.result[0].temp / 100,
            lm: res.result[0].lm1 + res.result[0].lm2,
          }
          this.Dex = res.result[0].ex / 100;
          this.hasWarmingData = true;
        } else {
          this.hasWarmingData = false;
        }
      });
      //初始化都通过
      Promise.all([busPro, GWPro])
          .then(() => {
            this.loading = false;
            this.hasData = true;
          })
          .catch((err) => {
            //初始化不通过
            console.log(err);
            this.loading = false;
            this.hasData = false;
          });
    },
    upDate(item, busData) {
      this.warmingData = {
        ex: item.ex / 100,
        temp: item.temp / 100,
        lm: item.lm1 + item.lm2,
        created_at: dayjs
            .unix(item.created_at)
            .format("YYYY年MM月DD HH:mm:ss"),
      }
      this.busDetail.last_online_at = busData.last_online_at
      this.busDetail.low_fuel_warning = busData.low_fuel_warning
      this.Dex = item.ex / 100;
    },
    onClose() {
      this.$emit("onClose");
    },
  },
};
</script>

<style scoped>
.busWin {
  display: flex;
  margin: 1% 5%;
  width: 90%;
  height: 98%;
  background-color: black;
  text-align: center;
  color: white;
  z-index: 993;
}

.title {
  width: 100%;
  padding-top: 40px;
  line-height: 80px;
  text-align: center;
}

.btn {
  position: absolute;
  right: 0;
  font-size: 16px;
  margin: 10px;
  z-index: 100;
}

.box {
  margin: 80px auto 0 auto;
  width: 400px;
  height: 600px;
  border: 8px solid rgba(215, 251, 253, 0.84);
  border-radius: 5px;
  box-shadow: 0 10px 40px rgba(0, 156, 255, 0.5);
  background: rgb(70, 174, 248);
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.wave {
  opacity: .4;
  position: absolute;
  top: 3%;
  left: 50%;
  background: rgba(255, 255, 255, 1);
  width: 800px;
  height: 800px;
  margin-left: -400px;
  margin-top: -250px;
  transform-origin: 50% 48%;
  border-radius: 43%;
  animation: drift 3000ms infinite linear;
}

.wave.-three {
  animation: drift 5000ms infinite linear;
}

.wave.-two {
  animation: drift 7000ms infinite linear;
  opacity: .1;
}

.box:after {
  content: '';
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  z-index: 11;
  transform: translate3d(0, 0, 0);
}

@keyframes drift {
  from {
    transform: rotate(0deg);
  }
  from {
    transform: rotate(360deg);
  }
}

.detailText {
  font-size: x-large;
  margin-top: 20px;
}

.normal {
  background: rgb(70, 174, 248);
}

.danger {
  background: rgb(255, 0, 0);
}
</style>

